@import 'variables';

@import 'bootstrap/bootstrap';

@import 'navbar';
@import 'breadcrumb';

html, body {
    min-height: 100vh;
}

.appView {
    min-height: 100vh;
}

#loginView {
    display: grid;
    grid-template-columns: auto 300px auto;
    grid-template-rows: 1fr auto 1fr;
    grid-template-areas:
        '. . .'
        '. form .'
        '. . .'
    ;

    .templateContainer {
        grid-area: form;
    }
}

#mainView {
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
        'navbar navbar navbar'
        'search breadcrumb .'
        'search template .'
        'search items .'
        'search commands .'
    ;

    > .navbar {
        grid-area: navbar;
    }

    > .breadcrumbContainer {
        grid-area: breadcrumb;
        padding: 15px;

        .breadcrumb {
            margin-bottom: 0;
            background-color: $light;
        }
    }

    > .queriesContainer {
        grid-area: search;
        padding: 15px;
    }

    > .templateContainer {
        grid-area: template;
        padding: 15px;
    }

    > .itemsContainer {
        grid-area: items;
        padding: 15px;

        .bottomButtonsContainer {
            margin-top: -15px;

            .btn {
                margin-top: 15px;
            }
        }
    }

    > .commandsContainer {
        grid-area: commands;
        align-content: flex-start;
        padding: 0 15px 15px;

        form {
            margin-top: 15px;
        }
    }
}
