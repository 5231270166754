.navbar-collapse {
    justify-content: center;
}

.navbar-brand {
    position: absolute;

    .navbar-light & {
        color: $link-color;

        &:hover, &:focus {
            color: $link-hover-color;
        }
    }
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-nav {
    .nav-item {
        border-bottom: 2px solid transparent;

        &.active {
            border-bottom-color: $link-color;
        }
    }
    
    .navbar-light & {
        .nav-link {
            color: $link-color;
            padding-top: 16px;
            padding-bottom: 14px;

            &:hover, &:focus {
                color: $link-hover-color;
            }
        }

        .active > .nav-link {
            color: $link-color;
        }
    }
}
